<template>
  <el-dialog top="12vh" width="35vw" visible append-to-body custom-class="rounded" :before-close="close">
    <template #title>
      <h2 class="text-typography-primary p-4">
        {{ $t('document.documentRecording.rescanTask.createRescanTaskModal.title') }}
      </h2>
    </template>
    <div class="p-4">
      <h3 class="mb-4 text-typography-primary">
        {{ $t('document.documentRecording.rescanTask.createRescanTaskModal.rescanReason') }}
      </h3>
      <el-form ref="form" :model="formModel" class="mb-7">
        <el-form-item prop="rescanReason" required>
          <el-select v-model="formModel.rescanReason" filterable>
            <el-option
              v-for="reason in DOCUMENT_RESCAN_TASK_REASON"
              :key="reason"
              :value="reason"
              :label="$t(`document.documentRecording.rescanTask.reason.${reason}`)"
            />
          </el-select>
        </el-form-item>
      </el-form>

      <div class="d-flex justify-content-end">
        <Button type="secondary" @click="close">{{ $t('commons.cancel') }}</Button>
        <Button @click="handleRescanTaskCreate">{{ $t('commons.apply') }}</Button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { Button } from '@/modules/core';

const DOCUMENT_RESCAN_TASK_REASON = ['blurryDocument', 'pageMissing', 'documentCut'];

export default {
  components: {
    Button,
  },
  data() {
    return {
      DOCUMENT_RESCAN_TASK_REASON,
      formModel: {
        rescanReason: null,
      },
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    handleRescanTaskCreate() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('createRescanTask', this.formModel.rescanReason);
          this.close();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__header {
  padding: 0;
}

h2 {
  font-size: 1.3rem;
}

h3 {
  font-size: 1rem;
}
</style>
