<template>
  <small>
    <DocumentAlreadyExistDropdown
      :text="$t('document.validation.documentNumber.documentMightAlreadyExist')"
      :dropdown-options="dropdownOptions"
      @on-dropdown-choice="(val) => $emit(val.action, '')"
    >
    </DocumentAlreadyExistDropdown>
  </small>
</template>

<script>
import { computed, getCurrentInstance } from 'vue';
import DocumentAlreadyExistDropdown from './DocumentAlreadyExistDropdown.vue';
import { DOCUMENT_TYPES } from '@/modules/document/types';

export default {
  components: {
    DocumentAlreadyExistDropdown,
  },
  props: {
    isDocumentNumberAlreadyExists: {
      type: Boolean,
    },
    isReplicateOf: {
      type: Boolean,
    },
    documentType: {
      type: String,
      default: '',
    },
  },
  emits: ['on-show-document', 'on-toggle-replicate-of'],
  setup(props) {
    const { $i18n } = getCurrentInstance().proxy;
    const ALLOWED_REPLICATION_DOCUMENTS_TYPES = [DOCUMENT_TYPES.DELIVERY_NOTE, DOCUMENT_TYPES.INVOICE];

    const dropdownOptions = computed(() => [
      {
        action: 'on-show-document',
        label: $i18n.t('document.validation.documentNumber.displayExistingDocument'),
        icon: 'DocumentMagnifyGlass',
      },
      {
        action: 'on-toggle-replicate-of',
        label: props.isReplicateOf
          ? $i18n.t('document.validation.documentNumber.cancelReplicateOfDocumentMark')
          : $i18n.t('document.validation.documentNumber.markAsReplicated'),
        icon: props.isReplicateOf ? 'DocumentDuplicateCross' : 'DocumentDuplicate',
        disabled: !ALLOWED_REPLICATION_DOCUMENTS_TYPES.includes(props.documentType),
      },
    ]);
    return {
      dropdownOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

// TODO: check if need and if will be good for both of them.
// .error-position {
//   position: absolute;
//   top: 50px;
// }
</style>
