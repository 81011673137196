<template>
  <div>
    <el-tooltip
      :disabled="isPermitted"
      :content="$t('document.documentsOverview.tenantDocumentsTable.downloadFiles.notPermitted')"
      placement="top"
    >
      <Button
        :class="!isPermitted ? 'disabled-btn' : ''"
        type="primary"
        :disabled="isDownloadDisabled()"
        @click="handleDownload"
      >
        <div>
          {{ $t('document.documentsOverview.tenantDocumentsTable.downloadFiles.download') }}
          <LockInvIcon v-if="!isPermitted" class="lock-icon" :size="12" />
        </div>
      </Button>
    </el-tooltip>
    <el-dialog
      :visible.sync="progressVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      center
      width="25%"
    >
      <span slot="title" class="el-dialog__title">
        <h2 style="margin-top: 10vh">
          {{ $t('document.documentsOverview.tenantDocumentsTable.downloadFiles.downloadingFiles') }}
        </h2>
      </span>
      <el-progress
        :percentage="progressPercentage"
        :status="progressPercentage === 100 ? 'success' : undefined"
        :show-text="false"
        :stroke-width="18"
        class="my-7"
      />
      <h3 slot="footer" style="margin-bottom: 10vh">{{ progressPercentage | percent }}</h3>
    </el-dialog>
  </div>
</template>

<script>
import { gql } from '@apollo/client/core';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { computed } from 'vue';

import { Button } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import { LockInvIcon } from '@/assets/icons';

const getFileBlobFromUrl = (url, onprogress) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.onload = () => resolve(xhr.response);
    xhr.onerror = () => reject(xhr.response);
    xhr.onprogress = onprogress;
    xhr.send();
  });
};
export default {
  components: { Button, LockInvIcon },
  props: {
    documents: { type: Array, default: () => [] },
    limit: { type: Number, default: null },
    isPermitted: { type: Boolean, default: false },
  },
  setup() {
    const { currentTenant } = useTenancy();
    return {
      businessId: computed(() => currentTenant.value.id),
    };
  },
  data() {
    return {
      progressVisible: false,
      progressData: [],
    };
  },
  computed: {
    progressPercentage() {
      const loaded = this.progressData.reduce((total, data) => total + data.loaded, 0);
      const totalProgress = this.progressData.reduce((total, data) => total + data.total, 0);
      return totalProgress ? Math.floor((loaded / totalProgress) * 100) : 0;
    },
  },
  methods: {
    handleDownload() {
      if (this.documents.length === this.limit) {
        this.$confirm(
          this.$t('document.documentsOverview.tenantDocumentsTable.downloadFiles.confirm'),
          this.$t('document.documentsOverview.tenantDocumentsTable.downloadFiles.download'),
          {
            confirmButtonText: this.$t('commons.apply'),
            cancelButtonText: this.$t('commons.cancel'),
            type: 'info',
          }
        )
          .then(() => {
            this.downloadDocuments();
          })
          .catch(() => {
            return;
          });
      } else {
        this.downloadDocuments();
      }
    },
    async downloadDocuments() {
      this.progressVisible = true;
      let firstProgress = true;
      this.progressData = this.documents.map(() => ({ loaded: 0, total: 0 }));

      const {
        data: {
          documentsNew2: { nodes: fileUrls },
        },
      } = await this.$apollo.query({
        query: gql`
          query ($businessId: ID!, $ids: [ID]) {
            documentsNew2(businessId: $businessId, ids: $ids) {
              totalCount
              nodes {
                id
                filePathUrl
              }
            }
          }
        `,
        variables: { businessId: this.businessId, ids: this.documents.map(({ id }) => id) },
      });
      const files = await Promise.all(
        this.documents.map((doc, index) =>
          getFileBlobFromUrl(fileUrls.find(({ id }) => id === doc.id).filePathUrl, (event) => {
            this.progressData[index].loaded = event.loaded;
            this.progressData[index].total = event.total;
            if (firstProgress) {
              this.progressData.forEach((data) => (data.total = event.total));
              firstProgress = false;
            }
          })
        )
      );

      const zip = new JSZip();
      files.forEach((file, index) => zip.file(`${index}-${this.documents[index].documentNumber}.pdf`, file));
      const content = await zip.generateAsync({ type: 'blob' });
      FileSaver.saveAs(
        content,
        `${this.$t('document.documentsOverview.tenantDocumentsTable.downloadFiles.filename')}.zip`
      );

      setTimeout(() => {
        this.progressVisible = false;
        this.progressData = [];
      }, 500);
    },
    isDownloadDisabled() {
      return !this.documents.length || !this.isPermitted;
    },
  },
};
</script>

<style scoped>
.lock-icon {
  margin-bottom: 4px;
  margin-right: 4px;
}

.disabled-btn {
  background-color: #f3f3f4;
  color: #94989f;
}

.disabled-btn:hover {
  background-color: #f3f3f4;
  color: #94989f;
}
</style>
