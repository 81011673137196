<template>
  <div>
    <div class="mb-4">
      <div v-if="['unProcessed', 'failed'].includes(ocrStatus)" class="text-center">
        <el-button @click="processOCR">Process OCR</el-button>
      </div>
      <div v-else-if="ocrStatus === 'inProgress'" class="text-center">
        <el-button disabled icon="el-icon-loading">Processing</el-button>
      </div>
      <div v-else v-loading="!ocrDocument" class="d-flex justify-content-between align-items-center border rounded p-2">
        Extraction mode
        <el-select v-model="mode" size="small">
          <el-option value="default" label="Default" />
          <el-option value="magicClick" label="Magic Click" />
        </el-select>
      </div>
    </div>
    <ocr-document
      v-if="doc.filePathUrl"
      ref="ocrDoc"
      :file-name="fileName"
      :is-replicate-document="!!doc.replicateOf"
      :doc-src="doc.filePathUrl"
      :ocr-doc="ocrDocument"
      :mode="mode"
      :data-fields="schemaKeys"
      :table-fields="tableSchemaKeys"
      @blocksSelection="extractText"
      @copyTo="onCopyTo"
    />
  </div>
</template>

<script type="text/javascript">
import xml2js from 'xml2js';
import { Document, Block } from '@clarityo/ocr-reader';
import { runOcrOnDocument } from '../compositions/documents-operations';
import { DOCUMENT_QUERY_NEW2 } from '../compositions/documents-queries';

import OcrDocument from './OcrDocument';
import documentSchemas from '@/imports/api/schemas/documents';

const getOCRDocument = async (url) => {
  const response = await fetch(url);
  const xmlString = await response.text();
  const parser = new xml2js.Parser({ attrValueProcessors: [xml2js.processors.parseNumbers] });
  return new Promise((resolve, reject) => {
    parser.parseString(xmlString, (err, result) =>
      err ? reject(err) : resolve(Document.createFromXml(result.document))
    );
  });
};

const getGoogleOCRDocument = async (url) => {
  const response = await fetch(url);
  const jsonString = await response.text();
  return Document.parseGoogleOCRData(jsonString);
};

export default {
  components: { OcrDocument },
  props: {
    doc: { type: Object, default: null },
    docTemplateData: { type: Object, default: null },
  },
  data() {
    return {
      mode: 'magicClick',
      ocrDocument: null,
    };
  },
  computed: {
    schemaKeys() {
      const { documentType } = this.docTemplateData;
      if (!documentType) return [];
      return documentSchemas[documentType].objectKeys();
    },
    fileName() {
      return this.extractFileNameFromPath(this.doc?.filePath);
    },
    tableSchemaKeys() {
      const { documentType } = this.docTemplateData;
      if (!documentType) return {};
      const documentSchema = documentSchemas[documentType];
      const tableSchemaKeys = {};
      this.schemaKeys.forEach((key) => {
        const keyType = documentSchema.schema(key).type.singleType;
        if (keyType !== Array) return;
        const fieldSchema = documentSchema.schema(`${key}.$`).type.singleType;
        tableSchemaKeys[key] = fieldSchema.objectKeys();
      });
      return tableSchemaKeys;
    },
    ocrStatus() {
      return this.doc.ocr.google && this.doc.ocr.google.status !== 'unProcessed'
        ? this.doc.ocr.google.status
        : this.doc.ocr.status;
    },
  },
  watch: {
    ocrStatus: {
      handler(value) {
        if (this.intervalId) {
          clearInterval(this.intervalId);
          this.intervalId = null;
        }

        if (value === 'inProgress') {
          this.intervalId = setInterval(() => {
            this.updateOcr(this.doc.id);
          }, 3 * 1000);
        }
      },
      immediate: true,
    },
    'doc.ocr.originalPathUrl': {
      handler(value) {
        this.ocrDocument = null;
        const docId = this.doc.id;
        if (!value) return;
        getOCRDocument(value)
          .then((ocrDoc) => {
            if (docId !== this.doc.id) return;
            this.ocrDocument = ocrDoc;
          })
          .catch((err) => {
            if (docId !== this.doc.id) return;
            console.error(err);
            this.$notify.error({ title: err.error, message: err.reason });
          });
      },
      immediate: true,
    },
    'doc.ocr.google.generalPathUrl': {
      handler(value) {
        this.ocrDocument = null;
        const docId = this.doc.id;
        if (!value) return;
        getGoogleOCRDocument(value)
          .then((ocrDoc) => {
            if (docId !== this.doc.id) return;
            this.ocrDocument = ocrDoc;
          })
          .catch((err) => {
            if (docId !== this.doc.id) return;
            console.error(err);
            this.$notify.error({ title: err.error, message: err.reason });
          });
      },
      immediate: true,
    },
  },
  destroyed() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  },
  methods: {
    extractFileNameFromPath(filePath = '') {
      const filenameWithExtension = filePath?.split('/').pop();
      return filenameWithExtension;
    },
    processOCR() {
      const id = this.doc.id;
      runOcrOnDocument({ id })
        .then(() => {
          this.updateOcr(id);
        })
        .catch((err) => {
          this.$message.error(`${err.error} - ${err.reason}`);
        });
    },
    updateOcr(docId) {
      this.$apollo.query({
        query: DOCUMENT_QUERY_NEW2,
        variables: { id: docId },
      });
    },
    extractText(blocks) {
      const sortedBlocks = [...blocks].sort(Block.compareVertically);
      this.$emit(
        'textExtraction',
        sortedBlocks.map((block) => block.text)
      );
    },
    onCopyTo(field, data) {
      if (!field) this.$emit('textExtraction', data.split('\n'));
      else this.$emit('dataExtraction', { [field]: data });
    },
  },
};
</script>
