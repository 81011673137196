<template>
  <div class="bg-expandable">
    <Table
      :columns="columns"
      :data="tableData"
      custom-class="pages-table"
      small
      :cell-class="selectedRowClass"
      @row-click="handleRowClick"
    >
      <template #cell-approveSingle="{ rowIndex }" class="row">
        <Button
          v-if="showApprove(rowIndex)"
          :disabled="disableApprovement"
          :loading="showLoading(rowIndex)"
          type="secondary"
          class="m-1"
          @click.stop="approveSinglePage(rowIndex)"
        >
          {{ $t('document.documentsOverview.organizationTasks.table.approveAsDocument') }}
        </Button>
        <div v-else class="approve-placeholder"></div>
      </template>
    </Table>
  </div>
</template>

<script>
import { Table, Button } from '@/modules/core';

const PAGE_TABLE_HEADER = {
  PAGE_NUMBER: 'pageNumber',
  APPROVE_SINGLE: 'approveSingle',
};

export default {
  components: {
    Table,
    Button,
  },
  props: {
    document: { type: Object },
  },
  data() {
    return {
      selectedRow: null,
      indexInApproveProcess: null,
      disableApprovement: false,
    };
  },
  computed: {
    columns() {
      return [
        {
          key: PAGE_TABLE_HEADER.PAGE_NUMBER,
        },

        {
          key: PAGE_TABLE_HEADER.APPROVE_SINGLE,
          width: '175px',
        },
      ];
    },
    tableData() {
      const { PAGE_NUMBER } = PAGE_TABLE_HEADER;
      return Array.from({ length: this.document?.pageCount || 0 }, (_, index) => ({
        [PAGE_NUMBER]: this.$t('document.documentsOverview.organizationTasks.subTableDocumentInfo.pageNumber', {
          index: index + 1,
        }),
      }));
    },
  },
  methods: {
    handleRowClick(index) {
      this.selectedRow = index;
      return this.$emit('pageClicked', {
        pageNumber: index,
        documentId: this.document.id,
        clearSelectedPages: this.clearSelectedPages,
        approveCompleted: this.approveCompleted,
        fixPageAfterApprove: this.fixPageAfterApprove,
        getCurrentPage: this.getCurrentPage,
      });
    },
    showApprove(rowIndex) {
      return this.selectedRow === rowIndex || this.indexInApproveProcess === rowIndex;
    },
    selectedRowClass(rowIndex) {
      if (rowIndex === this.selectedRow) return 'bg-selected';
    },
    approveSinglePage(index) {
      this.indexInApproveProcess = index;
      const lastPage = this.document?.pageCount - 1;

      this.$emit('approveSinglePage', {
        pageNumber: index,
        documentId: this.document.id,
        enableApprove: this.enableApprove,
      });

      if (this.selectedRow === lastPage) {
        this.selectedRow = 0;
      }

      this.disableApprovement = true;
    },
    getCurrentPage() {
      return this.selectedRow;
    },
    fixPageAfterApprove() {
      this.selectedRow = this.selectedRow - 1;
      return this.selectedRow;
    },
    approveCompleted() {
      this.indexInApproveProcess = null;
    },
    showLoading(index) {
      return this.indexInApproveProcess === index;
    },
    enableApprove() {
      this.disableApprovement = false;
    },
    clearSelectedPages() {
      this.selectedRow = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.approve-placeholder {
  height: 40px;
}

.bg-expandable {
  background: #f7f9f6;
}
::v-deep .text-grey {
  color: #9295a5;
}
::v-deep .pages-table {
  th,
  td {
    background: #f3f3f4;
  }

  tr {
    .action-button {
      visibility: hidden;
    }

    &:hover .action-button {
      visibility: visible;
    }
  }
}
</style>
