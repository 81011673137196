<template>
  <div>
    <el-dropdown class="d-flex" trigger="click" @command="onDropdownClick">
      <a type="secondary" class="dropdown-button content">
        <span>
          {{ text }}
        </span>
        <ChevronIcon :size="16" />
      </a>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(dropdownOption, index) in dropdownOptions"
          :key="`buttonDropdown${index}`"
          :disabled="dropdownOption.disabled"
          :command="dropdownOption.action"
        >
          <component :is="dropdownOption.icon"></component>
          <span class="dropdown-label">
            {{ dropdownOption.label }}
          </span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import { ChevronIcon, DocumentDuplicate, DocumentDuplicateCross, DocumentMagnifyGlass } from '@/assets/icons';

export default {
  components: {
    ChevronIcon,
    DocumentDuplicate,
    DocumentDuplicateCross,
    DocumentMagnifyGlass,
  },
  props: {
    type: { type: Boolean, default: false },
    dropdownOptions: { type: Array, required: true },
    tooltipText: { type: String, default: undefined },
    text: { type: String, default: undefined },
  },
  emits: ['on-dropdown-choice'],
  setup(_, { emit }) {
    const onDropdownClick = (action) => {
      emit('on-dropdown-choice', { action });
    };

    return {
      onDropdownClick,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.el-dropdown-menu {
  margin-top: 5px;
}
.dropdown-label {
  margin: auto 8px;
}
.dropdown-button {
  padding: 0.25rem;
  margin: 0;
}
.content {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  padding-inline-start: 0.25rem;
  color: $checkbox-error;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
